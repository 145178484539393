import './styles/main.css';

import Nav from './components/Nav';
import Jumbotron from './components/Jumbotron';
import ActivityPicture from './img/activities.jpg';
import VirtualPicture from './img/virtual.jpg';
import GuidePicture from './img/guide.jpg';

import { AwesomeButton } from "react-awesome-button";
import "react-awesome-button/dist/styles.css";
 
function App() {
  return (
    <div className="App">
      <Nav />       
      <Jumbotron />

      <div id="content-section">
        <div className="content-bubble">
          <div className="content-bubble-image-wrapper">
            <img src={VirtualPicture} />
          </div>
          <div className="content-text-wrapper">
            <h2>Virtual Workshops</h2>
            <p>Our virtual workshops feature the same action-packed experience as our live events.  All that’s needed is an internet connection and whatever crafts & building toy materials participants have lying around the house.</p>  <p>If you’re a library or school looking to supplement your off-site offerings, this is a great way to bring your community together and have some fun imagining, building, and sharing. </p>
            <a href="mailto:contact@imagarena.org?subject=Virtual Workshop Info Request" target="_blank"><AwesomeButton type="primary"><h3>Request Workshop Info</h3></AwesomeButton></a>
          </div>
        </div>

      <div className="content-bubble">
          <div className="content-bubble-image-wrapper">
            <img src={ActivityPicture} />
          </div>
          <div className="content-text-wrapper">
            <h2>Monthly Newsletter</h2>
            <p>If you’re a parent looking for tips and tricks on how to plan for those long days indoors, we have a number of ideas that we think you’ll find useful.</p><p>Each month we’ll send you a collection of ideas and activities that leverage the crafts & building toys you already own, with an emphasis on self-directed play that doesn’t require your constant intervention.</p>
            <a href="http://eepurl.com/hkniEz" target="_blank"><AwesomeButton type="primary"><h3>Join Mailing List</h3></AwesomeButton></a>
          </div>
        </div>
        
      <div className="content-bubble">
          <div className="content-bubble-image-wrapper">
            <img src={GuidePicture} />
          </div>
          <div className="content-text-wrapper">
            <h2>Building Toy Buying Guide</h2>
            <p>Over the many years of running our ImagArena workshops in schools, libraries, and homes across New England, we’ve learned a thing or two about what makes a building toy great.</p><p>So we thought it would be helpful to put together a list of the Top 10 Building Toys we think every collection should have.  Use it as a holiday shopping guide or as inspiration for your next building toy purchase.</p>
            <a href="https://storage.googleapis.com/beta-imagarena-files/ImagArena_Building_Toy_Buying_Guide_2020.pdf" target="_blank"><AwesomeButton type="primary"><h3>Download Buying Guide</h3></AwesomeButton></a>
          </div>
        </div>
      </div>
      
      <div id="footer">
        <h4>For all other requests and inquiries, email us at <a href="mailto:contact@imagarena.org">contact@imagarena.org</a></h4>
        <h5>Copyright © 2020 ImagArena, LLC</h5>
      </div>
    </div>
  );
}

export default App;
