import React from 'react'
import ReactPlayer from 'react-player'

import '../styles/jumbotron.css';

export default function Jumbotron() {
  return (
    <div id="jumbotron-container">
      <h1>An imagination experience for ages 6+</h1>

      <div id="header">
        <div id="player-wrapper">
          <ReactPlayer 
            className='react-player'
            width='100%'
            height='100%'
            url='https://www.youtube.com/watch?v=-mhQzPnsTr4' 
            />
        </div>
      </div>
      
      
      <div id="info-bar">
        <h4>
          Due to the <strong>COVID-19</strong> pandemic, we have adjusted our offerings to feature virtual programming, activity guides, and more!  
        </h4>
      </div>
      
    </div>
  )
}