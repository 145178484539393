import React from 'react';
import '../styles/nav.css';

import Logo from '../img/ia_white_logo_w_text.png';

export default function Nav() {
  return (
    <div id="nav-container">
      <img id="logo" src={Logo} />
    </div>  
  )
}